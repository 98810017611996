import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import HeadingMain from '../components/HeadingMain'
import StyledLink from '../components/styles/StyledLink'

const Container = styled.div`
  text-align: center;
`

const Back = styled.div`
  margin-top: -20px;
`

const NoPage = () => {
  return (
    <React.Fragment>
      <HeadingMain mainHeading="Hoppla ..." subHeading="Diese Seite konnte leider nicht gefunden werden." />
      <Container>
        <Back>
          Zurück zur{' '}
          <Link to="/">
            <StyledLink>Homepage</StyledLink>
          </Link>
        </Back>
      </Container>
    </React.Fragment>
  )
}

export default NoPage
